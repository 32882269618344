<template>
	<section v-if="reviews.length" class="main-content">
		<div class="row align-center">
			<div class="columns column12 review-slider align-center">
				<h2>{{ header }}</h2>
				<carousel :per-page="1" :pagination-position="'bottom-overlay'" class="carousel">
					<slide v-for="review in reviews" :key="review.ID" class="review-slider-content">
						<p>{{ review.content }}</p>
						<div class="source">
							<div class="source-logo" />
							<div>
								<strong>{{ review.firstName }}</strong>
								<span v-if="review.date">
									{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
								</span>
							</div>
						</div>
					</slide>

					<template #addons>
						<pagination />
					</template>
				</carousel>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.main-content {
	background: #f4f2ec;
}

.carousel button {
	padding: 0 !important;
	width: 18px !important;
	height: 18px !important;
	border: 1px solid #415364 !important;
}

.review-slider {
	padding: 50px 75px;
	margin: 0 auto;
	overflow: visible;
	background: #fff;
	float: none;
	position: relative;
	width: calc(100% - 220px);
	max-width: 1080px;

	.review-slider-content {
		hr {
			margin: 25px 0;
			opacity: 0.2;
		}

		.source {
			width: 300px;
			max-width: 100%;
			margin: 20px auto;
			text-align: center;
			display: flex;
			flex-flow: nowrap row;
			align-items: center;
			justify-content: center;

			div {
				display: flex;
				flex-flow: column wrap;
				align-items: flex-start;
				justify-content: flex-start;
			}

			strong {
				color: #4b4227;
				float: left;
				width: 100%;
				margin: 0;
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__slide) {
	flex-direction: column;
}

:deep(.carousel__pagination-item) {
	margin: 0 4px;

	button {
		width: 11px;
		height: 11px;
		border: 1px solid #fff;
		border-radius: 50%;
		background: rgb(239 239 239);

		&.carousel__pagination-button--active {
			background: #222;
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;

		&::before {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 40px 70px 35px;
		overflow: visible;
		width: 100%;

		.review-slider-content p .review-source {
			display: block;
			float: right;
			margin: 40px 0 0 20px;
		}

		.review-slider-content .source .source-logo {
			margin: 0;
		}
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 30px;
	}
}
</style>
